ul {
    padding: 20px;
}

.text-align {
    text-align: left;
}

.before-footer{
    margin-bottom: 20%;
}