.card-height{
    display: block;
    width: 100%;
    height: 360px;
    background-color: #475;
    overflow:hidden;
    padding: 10px;
}


