.overlay {
        position: absolute;
        left: -20;
        top: 0;
        right: -20;
        width: 100%;
        height: 100%;
        background: rgba(10, 7, 7, 0.90);
        color: #000;
}

.space-right{
        margin-right: 10px;
        font-family: "Fira Sans", sans-serif;
}

navbar {
        background-color: #000;
}