.floatRight {
    float: right;
}

.floatLeft {
    float: left;
}

.resourceList {
    margin-top: 5%;
    padding: 20px;
}